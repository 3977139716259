<!-- 进修招聘 进修内容管理  邓-->
<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <div class="datePicker">
      <div>
        年份：
        <a-date-picker
          mode="year"
          placeholder="请选择年份"
          format="YYYY"
          v-model="year"
          style="width: 200px"
          :open="yearShowOne"
          @openChange="openChangeOne"
          @panelChange="panelChangeOne"
        />
      </div>

      <div>
        关键字：
        <a-input
          style="width: 200px"
          placeholder="请输入标题/医院/科室"
          v-model="name"
        ></a-input>
      </div>
      <div>
        <a-radio-group
          default-value="1"
          @change="callback($event)"
          v-model="state"
        >
          状态：
          <a-radio-button value="0"> 未发布</a-radio-button>
          <a-radio-button value="1"> 已发布 </a-radio-button>
        </a-radio-group>
      </div>
      <div>
        <span>到期状态</span>: &nbsp;
        <a-select
          default-value="全部"
          style="width: 120px"
          v-model="deadlinState"
          @change="handleChange"
        >
          <a-select-option value="全部"> 全部 </a-select-option>
          <a-select-option value="未到期"> 未到期 </a-select-option>
          <a-select-option value="已到期"> 已到期 </a-select-option>
        </a-select>
      </div>
      <div>
        <a-button type="primary" @click="search">查询</a-button>
        <a-button style="margin-left: 10px" @click="clear">重置</a-button>
      </div>
    </div>

    <div class="body">
      <div>
        <a-button type="primary" style="width: 100px" @click="createdByHospital"
          >按医院创建</a-button
        >
        <a-button
          type="primary"
          style="width: 100px; margin-left: 20px"
          @click="createdByKeshi"
          >按科室创建</a-button
        >
      </div>
      <div style="width: 100%; margin: auto; margin-top: 20px">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :scroll="{ x: 1900 }"
          :pagination="false"
        >
          <span slot="time" slot-scope="text, record, index">
            {{ record.startTime }} —— {{ record.endTime }}
          </span>
          <span slot="biaoji" slot-scope="text, record, index">
            <a-switch
              :checked="record.isTop === 1 ? true : false"
              @change="isTopSwitch(record.isTop === 0 ? 1 : 0, record)"
              checked-children="置顶"
              un-checked-children="置顶"
              default-checked
            />
            <a-switch
              style="margin-left: 20px"
              :checked="record.isNew === 1 ? true : false"
              @change="isNewSwitch(record.isNew === 0 ? 1 : 0, record)"
              checked-children="新"
              un-checked-children="新"
              default-checked
            />
            <a-switch
              style="margin-left: 20px"
              :checked="record.isImpatient === 1 ? true : false"
              @change="
                isImpatientSwitch(record.isImpatient === 0 ? 1 : 0, record)
              "
              checked-children="急"
              un-checked-children="急"
              default-checked
            />
          </span>
          <span slot="keshi" slot-scope="text, record, index">
            <span v-if="record.createdByType == 'department'">
              <span v-for="item in record.educationHospitalDepartment">
                {{ item.name }}
              </span>
            </span>
            <a @click="openSetup(record)" v-else>设置</a>
          </span>
          <span slot="fabu" slot-scope="text, record, index">
            <a-switch
              style="margin-left: 20px"
              :checked="record.isPublish === 1 ? true : false"
              @change="isPublishSwitch(record.isPublish === 0 ? 1 : 0, record)"
              checked-children="是"
              un-checked-children="否"
              default-checked
            />
          </span>
          <span slot="isDeadLine1" slot-scope="text, record, item">
            <a-tag color="red" v-if="record.isDeadlineTime == 1">已到期</a-tag>
            <a-tag color="green" v-else>未到期</a-tag>
          </span>
          <span slot="operation" slot-scope="text, record, index">
            <div style="display: flex; justify-content: space-around">
              <a @click="openRecommend(record)">推广</a>
              <a @click="edit(record)">修改</a>
              <a-popconfirm
                title="是否确定删除?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="del(record.id)"
                @cancel="delCancel"
              >
                <a href="#">删除</a>
              </a-popconfirm>
            </div>
          </span>
        </a-table>
      </div>
      <div
        style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ total }}条记录 第 {{ curPage }} /
          {{ Math.ceil(total / pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pageSize"
          :total="total"
          v-model="curPage"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
    <!-- 新增对话框 医院 -->
    <a-drawer
      title="按医院创建"
      :width="700"
      :visible="addHospitalVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="addHospitalCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="addHospitalForm"
        ref="ruleHospitalForm_add"
        :rules="addHospitalRules"
      >
        <a-form-model-item label="进修标题" prop="title">
          <a-input
            placeholder="请输入"
            v-model="addHospitalForm.title"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="年限" prop="cycle">
          <a-select placeholder="请选择" v-model="addHospitalForm.cycle">
            <a-select-option v-for="item in NX" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="学历" prop="education">
          <a-select placeholder="请选择" v-model="addHospitalForm.education">
            <a-select-option v-for="item in XL" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="住宿" prop="stay">
          <a-select placeholder="请选择" v-model="addHospitalForm.stay">
            <a-select-option v-for="item in ZS" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="标签">
          <div>
            <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter="handleInputConfirm"
            />
            <a-tag
              v-else
              style="background: #fff; borderstyle: dashed"
              @click="showInput"
            >
              <a-icon type="plus" /> 标签
            </a-tag>
          </div>
          <div>
            <template v-for="(tag, index) in tags">
              <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                <a-tag
                  :key="tag"
                  :closable="index !== -1"
                  @close="() => handleClose(tag)"
                >
                  {{ `${tag.slice(0, 20)}...` }}
                </a-tag>
              </a-tooltip>
              <a-tag
                v-else
                :key="tag"
                :closable="index !== -1"
                @close="() => handleClose(tag)"
              >
                {{ tag }}
              </a-tag>
            </template>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="省份/城市"
          placeholder="请选择"
          prop="province"
        >
          <a-cascader
            v-model="addHospitalForm.province"
            :options="hospitaloPtions"
            placeholder="请选择"
            @change="provinceChange"
          />
        </a-form-model-item>
        <a-form-model-item label="医院" placeholder="请选择" prop="hospitalId">
          <a-select
            style="width: 460px"
            placeholder="请选择"
            show-search
            optionFilterProp="label"
            @search="select_hospital"
            @change="hospitalSeleChange"
            @popupScroll="popupScroll_questionCode"
            v-model="addHospitalForm.hospitalId"
          >
            <a-select-option
              v-for="item in hospitalNameList"
              :value="item.id"
              :label="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
           <a-form-model-item label="一级科室" prop="firstLevelHospitalDepartment">
            <a-select
                v-model="addHospitalForm.firstLevelHospitalDepartment"
                placeholder="请选择"
                show-search
                mode="multiple"
            >
              <a-select-option
                v-for="item in YJKS"
                :value="item"
                >
                {{item}}
              </a-select-option>
            </a-select>
         </a-form-model-item>
        <a-form-model-item label="二级科室" prop="hospitalDepartmentId">
          <a-select
            mode="multiple"
            style="width: 100%"
            v-model="addHospitalForm.hospitalDepartmentId"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in keshiList"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报名开始日期" prop="startTime">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            v-model="addHospitalForm.startTime"
            style="width: 460px"
            placeholder="请选择"
            @change="onStartHospitalChange"
          />
        </a-form-model-item>
        <a-form-model-item label="报名结束日期" prop="endTime">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            v-model="addHospitalForm.endTime"
            style="width: 460px"
            placeholder="请选择"
            @change="onEndHospitalChange"
          />
        </a-form-model-item>
        <a-form-model-item label="下架时间">
          <a-date-picker
            showTime
            v-model="addHospitalForm.deadlineTime"
            style="width: 460px"
            placeholder="请选择"
            @change="ondeadlineTimeHospitalChange"
          />
        </a-form-model-item>
        <a-form-model-item label="进修内容" prop="introduce">
          <!-- <a-input v-model="addHospitalForm.introduce"></a-input> -->
          <div
            v-if="addHospitalVisible == true"
            style="border: 1px solid #ccc; min-height: 500px; width: 500px"
          >
            <!-- <a-input v-model="addKeshiForm.introduce"></a-input> -->
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: hidden"
              v-model="addHospitalForm.introduce"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="addHospitalCancel"> 取消 </a-button>
        <a-button
          style="margin-left: 10px"
          type="primary"
          @click="addHospitalOK"
        >
          确定
        </a-button>
      </div>
    </a-drawer>
    <!-- 新增对话框 -->
    <a-drawer
      title="按科室创建"
      :width="700"
      :visible="addKeshiVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="addKeshiCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="addKeshiForm"
        ref="ruleKeshiForm_add"
        :rules="addHospitalRules"
      >
        <a-form-model-item label="进修标题" prop="title">
          <a-input placeholder="请输入" v-model="addKeshiForm.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="年限" prop="cycle">
          <a-select placeholder="请选择" v-model="addKeshiForm.cycle">
            <a-select-option v-for="item in NX" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="学历" prop="education">
          <a-select placeholder="请选择" v-model="addKeshiForm.education">
            <a-select-option v-for="item in XL" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="住宿" prop="stay">
          <a-select placeholder="请选择" v-model="addKeshiForm.stay">
            <a-select-option v-for="item in ZS" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="标签">
          <div>
            <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter="handleInputConfirm"
            />
            <a-tag
              v-else
              style="background: #fff; borderstyle: dashed"
              @click="showInput"
            >
              <a-icon type="plus" /> 标签
            </a-tag>
          </div>
          <div>
            <template v-for="(tag, index) in tags">
              <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                <a-tag
                  :key="tag"
                  :closable="index !== -1"
                  @close="() => handleClose(tag)"
                >
                  {{ `${tag.slice(0, 20)}...` }}
                </a-tag>
              </a-tooltip>
              <a-tag
                v-else
                :key="tag"
                :closable="index !== -1"
                @close="() => handleClose(tag)"
              >
                {{ tag }}
              </a-tag>
            </template>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="省份/城市"
          placeholder="请选择"
          prop="province"
        >
          <a-cascader
            v-model="addKeshiForm.province"
            :options="hospitaloPtions"
            placeholder="请选择"
            @change="provinceChange"
          />
        </a-form-model-item>
        <a-form-model-item label="医院" placeholder="请选择" prop="hospitalId">
          <a-select
            style="width: 460px"
            placeholder="请选择"
            show-search
            optionFilterProp="label"
            @search="select_2hospital"
            @change="hospitalSeleChange"
            v-model="addKeshiForm.hospitalId"
            @popupScroll="PopupScroll_questionCodeTwo"
          >
            <a-select-option
              v-for="item in hospitalNameList"
              :value="item.id"
              :label="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="一级科室" prop="firstLevelHospitalDepartment">
            <a-select
                v-model="addKeshiForm.firstLevelHospitalDepartment"
                placeholder="请选择"
                show-search
                 mode="multiple"
            >
              <a-select-option
                v-for="item in YJKS"
                :value="item"
                >
                {{item}}
              </a-select-option>
            </a-select>
         </a-form-model-item>
        <a-form-model-item
          label="二级科室"
          placeholder="请选择"
          prop="hospitalDepartmentId"
        >
          <a-select
            placeholder="请选择"
            style="width: 100%"
            v-model="addKeshiForm.hospitalDepartmentId"
          >
            <a-select-option v-for="item in keshiList" :value="item.id"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报名开始日期" prop="startTime">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            v-model="addKeshiForm.startTime"
            style="width: 460px"
            placeholder="请选择"
            @change="onStartChange"
          />
        </a-form-model-item>
        <a-form-model-item label="报名结束日期" prop="endTime">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            v-model="addKeshiForm.endTime"
            style="width: 460px"
            placeholder="请选择"
            @change="onEndChange"
          />
        </a-form-model-item>
        <a-form-model-item label="下架时间">
          <a-date-picker
            showTime
            v-model="addKeshiForm.deadlineTime"
            style="width: 460px"
            placeholder="请选择"
            @change="ondeadlineTimeChange"
          />
        </a-form-model-item>
        <a-form-model-item label="进修内容" prop="introduce">
          <div
            v-if="addKeshiVisible == true"
            style="border: 1px solid #ccc; min-height: 500px; width: 500px"
          >
            <!-- <a-input v-model="addKeshiForm.introduce"></a-input> -->
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: hidden"
              v-model="addKeshiForm.introduce"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="addKeshiCancel"> 取消 </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="addKeshiOK">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!-- 编辑 按科室 -->
    <a-drawer
      title="按科室编辑"
      :width="700"
      :visible="editKeshiVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="editKeshiCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="editKeshiForm"
        ref="ruleKeshiForm_edit"
        :rules="addHospitalRules"
      >
        <a-form-model-item label="进修标题" prop="title">
          <a-input placeholder="请输入" v-model="editKeshiForm.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="年限" prop="cycle">
          <a-select placeholder="请选择" v-model="editKeshiForm.cycle">
            <a-select-option v-for="item in NX" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="学历" prop="education">
          <a-select placeholder="请选择" v-model="editKeshiForm.education">
            <a-select-option v-for="item in XL" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="住宿" prop="stay">
          <a-select placeholder="请选择" v-model="editKeshiForm.stay">
            <a-select-option v-for="item in ZS" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="标签">
          <div>
            <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter="handleInputConfirm"
            />
            <a-tag
              v-else
              style="background: #fff; borderstyle: dashed"
              @click="showInput"
            >
              <a-icon type="plus" /> 标签
            </a-tag>
          </div>
          <div>
            <template v-for="(tag, index) in tags">
              <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                <a-tag
                  :key="tag"
                  :closable="index !== -1"
                  @close="() => handleClose(tag)"
                >
                  {{ `${tag.slice(0, 20)}...` }}
                </a-tag>
              </a-tooltip>
              <a-tag
                v-else
                :key="tag"
                :closable="index !== -1"
                @close="() => handleClose(tag)"
              >
                {{ tag }}
              </a-tag>
            </template>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="省份/城市"
          placeholder="请选择"
          prop="provinceCity"
        >
          <a-cascader
            v-model="editKeshiForm.provinceCity"
            :options="hospitaloPtions"
            placeholder="请选择"
            @change="provinceChange"
          />
        </a-form-model-item>
        <a-form-model-item label="医院" placeholder="请选择" prop="hospitalId">
          <a-select
            style="width: 460px"
            placeholder="请选择"
            show-search
            optionFilterProp="label"
            @search="select_3hospital"
            @change="hospitalSeleChange"
            @popupScroll="editPopupScroll_questionCode"
            v-model="editKeshiForm.hospitalId"
          >
            <a-select-option
              v-for="item in hospitalNameList"
              :value="item.id"
              :key="item.id"
              :label="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
          <a-form-model-item label="一级科室" prop="firstLevelHospitalDepartment">
            <a-select
                v-model="editKeshiForm.firstLevelHospitalDepartment"
                placeholder="请选择"
                show-search
                 mode="multiple"
            >
              <a-select-option
                v-for="item in YJKS"
                :value="item"
                >
                {{item}}
              </a-select-option>
            </a-select>
         </a-form-model-item>
        <a-form-model-item
          label="二级科室"
          placeholder="请选择"
          prop="hospitalDepartmentId"
        >
          <a-select
            style="width: 100%"
            v-model="editKeshiForm.hospitalDepartmentId"
          >
            <a-select-option v-for="item in keshiList" :value="item.id"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报名开始日期" prop="startTime">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            style="width: 460px"
            placeholder="请选择"
            v-model="editKeshiForm.startTime"
          />
        </a-form-model-item>
        <a-form-model-item label="报名结束日期" prop="endTime">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            style="width: 460px"
            placeholder="请选择"
            v-model="editKeshiForm.endTime"
          />
        </a-form-model-item>
        <a-form-model-item label="下架时间">
          <a-date-picker
            showTime
            format="yyyy-MM-DD HH:mm:ss"
            value-format="yyyy-MM-DD HH:mm:ss"
            v-model="editKeshiForm.deadlineTime"
            style="width: 460px"
            placeholder="请选择"
          />
        </a-form-model-item>
        <a-form-model-item label="进修内容" prop="introduce">
          <!-- <a-input v-model="editKeshiForm.introduce"></a-input> -->
          <div
            v-if="editKeshiVisible == true"
            style="border: 1px solid #ccc; min-height: 500px; width: 500px"
          >
            <!-- <a-input v-model="addKeshiForm.introduce"></a-input> -->
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: hidden"
              v-model="editKeshiForm.introduce"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="editKeshiCancel"> 取消 </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="editKeshiOK">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!-- 编辑 按医院 -->
    <a-drawer
      title="按医院编辑"
      :width="700"
      :visible="editHospitalVisible"
      :body-style="{ paddingBottom: '50px' }"
      @close="editHospitalCancel"
    >
      <a-form-model
        :label-col="labelColLive"
        :wrapper-col="wrapperCol"
        :model="editHospitalForm"
        ref="ruleHospitalForm_edit"
        :rules="addHospitalRules"
      >
        <a-form-model-item label="进修标题" prop="title">
          <a-input
            placeholder="请输入"
            v-model="editHospitalForm.title"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="年限" prop="cycle">
          <a-select placeholder="请选择" v-model="editHospitalForm.cycle">
            <a-select-option v-for="item in NX" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="学历" prop="education">
          <a-select placeholder="请选择" v-model="editHospitalForm.education">
            <a-select-option v-for="item in XL" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="住宿" prop="stay">
          <a-select placeholder="请选择" v-model="editHospitalForm.stay">
            <a-select-option v-for="item in ZS" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="标签">
          <div>
            <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter="handleInputConfirm"
            />
            <a-tag
              v-else
              style="background: #fff; borderstyle: dashed"
              @click="showInput"
            >
              <a-icon type="plus" /> 标签
            </a-tag>
          </div>
          <div>
            <template v-for="(tag, index) in tags">
              <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                <a-tag
                  :key="tag"
                  :closable="index !== -1"
                  @close="() => handleClose(tag)"
                >
                  {{ `${tag.slice(0, 20)}...` }}
                </a-tag>
              </a-tooltip>
              <a-tag
                v-else
                :key="tag"
                :closable="index !== -1"
                @close="() => handleClose(tag)"
              >
                {{ tag }}
              </a-tag>
            </template>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="省份/城市"
          placeholder="请选择"
          prop="provinceCity"
        >
          <a-cascader
            v-model="editHospitalForm.provinceCity"
            :options="hospitaloPtions"
            placeholder="请选择"
            @change="provinceChange"
          />
        </a-form-model-item>
        <a-form-model-item label="医院" placeholder="请选择" prop="hospitalId">
          <a-select
            style="width: 460px"
            placeholder="请选择"
            show-search
            optionFilterProp="label"
            @search="select_4hospital"
            @change="hospitalSeleChange"
            @popupScroll="editPopupScroll_questionCodeTwo"
            v-model="editHospitalForm.hospitalId"
          >
            <a-select-option
              v-for="item in hospitalNameList"
              :value="item.id"
              :label="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
         <a-form-model-item label="一级科室" prop="firstLevelHospitalDepartment">
            <a-select
                v-model="editHospitalForm.firstLevelHospitalDepartment"
                placeholder="请选择"
                show-search
                 mode="multiple"
            >
              <a-select-option
                v-for="item in YJKS"
                :value="item"
                >
                {{item}}
              </a-select-option>
            </a-select>
         </a-form-model-item>
        <a-form-model-item
          label="二级科室"
          placeholder="请选择"
          prop="hospitalDepartmentId"
        >
          <a-select
            mode="multiple"
            style="width: 100%"
            v-model="editHospitalForm.hospitalDepartmentId"
          >
            <a-select-option v-for="item in keshiList" :value="item.id"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报名开始日期" prop="startTime">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            v-model="editHospitalForm.startTime"
            style="width: 460px"
            placeholder="请选择"
          />
        </a-form-model-item>
        <a-form-model-item label="报名结束日期" prop="endTime">
          <a-date-picker
            format="yyyy-MM-DD"
            value-format="yyyy-MM-DD"
            v-model="editHospitalForm.endTime"
            style="width: 460px"
            placeholder="请选择"
          />
        </a-form-model-item>
        <a-form-model-item label="下架时间">
          <a-date-picker
            showTime
            format="yyyy-MM-DD HH:mm:ss"
            value-format="yyyy-MM-DD HH:mm:ss"
            v-model="editHospitalForm.deadlineTime"
            style="width: 460px"
            placeholder="请选择"
          />
        </a-form-model-item>
        <a-form-model-item label="进修内容" prop="introduce">
          <!-- <a-input v-model="editHospitalForm.introduce"></a-input> -->
          <div
            v-if="editHospitalVisible == true"
            style="border: 1px solid #ccc; min-height: 500px; width: 500px"
          >
            <!-- <a-input v-model="addKeshiForm.introduce"></a-input> -->
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: hidden"
              v-model="editHospitalForm.introduce"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button @click="editHospitalCancel"> 取消 </a-button>
        <a-button
          style="margin-left: 10px"
          type="primary"
          @click="editHospitalOK"
        >
          确定
        </a-button>
      </div>
    </a-drawer>

    <!-- 设置弹窗 -->
    <a-modal
      title="设置"
      :width="800"
      :visible="setupVisible"
      @ok="setupOk"
      @cancel="setupCancel"
    >
      <div class="modalDiv">
        <!-- 左 -->
        <div style="border-right: 1px solid #e9e9e9">
          <div class="menu" id="menu">
            <a-menu mode="inline" v-model="selectedKeys">
              <a-menu-item
                v-for="item in menuList"
                :key="item.id"
                @click="setupChange(item.id)"
              >
                {{ item.name }}
              </a-menu-item>
            </a-menu>
          </div>
        </div>
        <!-- 右 -->
        <div class="content-list">
          <a-form-model
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 16 }"
            :model="setupForm"
            ref="setupForm"
            :rules="addHospitalRules"
          >
            <a-form-model-item style="display: flex" label="标识">
              <a-switch
                :checked="setupForm.isTop === 1 ? true : false"
                @change="isTopSetup(setupForm.isTop === 0 ? 1 : 0)"
                checked-children="置顶"
                un-checked-children="置顶"
                default-checked
              />
              <a-switch
                style="margin: 0px 10px"
                :checked="setupForm.isNew === 1 ? true : false"
                @change="isNewSetup(setupForm.isNew === 0 ? 1 : 0)"
                checked-children="新"
                un-checked-children="新"
                default-checked
              />
              <a-switch
                :checked="setupForm.isImpatient === 1 ? true : false"
                @change="isImpatientSetup(setupForm.isImpatient === 0 ? 1 : 0)"
                checked-children="急"
                un-checked-children="急"
                default-checked
              />
            </a-form-model-item>

            <a-form-model-item label="进修标题" prop="title">
              <a-input placeholder="请输入" v-model="setupForm.title"></a-input>
            </a-form-model-item>
            <a-form-model-item label="年限" prop="cycle">
              <a-select placeholder="请选择" v-model="setupForm.cycle">
                <a-select-option v-for="item in NX" :value="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="学历" prop="education">
              <a-select placeholder="请选择" v-model="setupForm.education">
                <a-select-option v-for="item in XL" :value="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="住宿" prop="stay">
              <a-select placeholder="请选择" v-model="setupForm.stay">
                <a-select-option v-for="item in ZS" :value="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="标签">
              <div>
                <a-input
                  v-if="inputVisible"
                  ref="input"
                  type="text"
                  size="small"
                  :style="{ width: '78px' }"
                  :value="inputValue"
                  @change="handleInputChange"
                  @blur="handleInputConfirm"
                  @keyup.enter="handleInputConfirm"
                />
                <a-tag
                  v-else
                  style="background: #fff; borderstyle: dashed"
                  @click="showInput"
                >
                  <a-icon type="plus" /> 标签
                </a-tag>
              </div>
              <div>
                <template v-for="(tag, index) in tags">
                  <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                    <a-tag
                      :key="tag"
                      :closable="index !== -1"
                      @close="() => handleClose(tag)"
                    >
                      {{ `${tag.slice(0, 20)}...` }}
                    </a-tag>
                  </a-tooltip>
                  <a-tag
                    v-else
                    :key="tag"
                    :closable="index !== -1"
                    @close="() => handleClose(tag)"
                  >
                    {{ tag }}
                  </a-tag>
                </template>
              </div>
            </a-form-model-item>
            <a-form-model-item label="备注信息">
              <a-textarea
                placeholder="请输入"
                v-model="setupForm.notes"
                style="min-height: 100px; overflow-y: scroll"
              />
            </a-form-model-item>
            <a-form-model-item label="报名开始日期" prop="startTime">
              <a-date-picker
                format="yyyy-MM-DD"
                value-format="yyyy-MM-DD"
                v-model="setupForm.startTime"
                style="width: 300px"
                placeholder="请选择"
                @change="onStartSetupChange"
              />
            </a-form-model-item>
            <a-form-model-item label="报名结束日期" prop="endTime">
              <a-date-picker
                format="yyyy-MM-DD"
                value-format="yyyy-MM-DD"
                v-model="setupForm.endTime"
                style="width: 300px"
                placeholder="请选择"
                @change="onEndSetupChange"
              />
            </a-form-model-item>
            <a-form-model-item label="下架时间">
              <a-date-picker
                showTime
                format="yyyy-MM-DD HH:mm:ss"
                value-format="yyyy-MM-DD HH:mm:ss"
                v-model="setupForm.deadlineTime"
                style="width: 300px"
                placeholder="请选择"
                @change="ondeadlineTimeSetupChange"
              />
            </a-form-model-item>
            <a-form-model-item label="进修内容" prop="introduce">
              <!-- <a-input v-model="setupForm.introduce"></a-input> -->
              <div
                v-if="setupVisible == true"
                style="border: 1px solid #ccc; min-height: 360px; width: 480px"
              >
                <!-- <a-input v-model="addKeshiForm.introduce"></a-input> -->
                <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
                />
                <Editor
                  style="height: 300px; overflow-y: hidden"
                  v-model="setupForm.introduce"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
                />
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>

    <!--   推广 -->
    <a-drawer
      title="推广"
      :width="500"
      :visible="promotionsVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="promotionsClose"
    >
      <div style="margin: 20px">
        <!-- 社区状态按钮 -->
        <a-table
          :row-key="(record) => record.id"
          :columns="communityColumns"
          :data-source="communityData"
          :pagination="false"
          bordered
        >
          <span slot="is_open" slot-scope="text, record">
            <a-switch
              v-model="record.is_open"
              @click="isRecommendSwitch(record.is_open)"
              checked-children="是"
              un-checked-children="否"
              default-checked
            />
          </span>
        </a-table>
      </div>
      <div style="margin:20px">
        <a-table :row-key="record => record.id" :columns="uniAppColumns" :data-source="uniAppData" bordered>
          <span slot="is_open" slot-scope="text, record">
             <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record)" default-checked />
           </span>
        </a-table>
      </div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getEducation,
  editEducation,
  getSelect,
  addEducation,
  getRecommend,
  postRecommend,
  delRecommend,
  delEducation,
  getChildEducation,
} from "@/service/trainManage_api";
import moment from "moment";
import { getHospital } from "@/service/hospital_api";
import { getHospitalDepartments } from "@/service/hospital_api";
import City from "@/assets/city.json";
import axios from "axios";
import { current_date } from "@/utils/currentDate";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import {postminaRecommend,getminaRecommend,delminaRecommend} from "@/service/Recruiting";
const editorConfig = {
  // JS 语法
  MENU_CONF: {},
};
const signature = "";
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF["uploadImage"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData();
    formData.append("file", file);
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios
      .post(
        "https://yun-new-admin-api.yunicu.com/api/admin/common/update",
        formData
      )
      .then((res) => {
        if (res.data.code === 0) {
          insertFn(res.data.data.url); // 最后插入图片
        }
      });
  },
};
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF["uploadVideo"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios
      .get("https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign")
      .then((res) => {
        if (res.data.code === 0) {
          const getSignature = () => {
            return res.data.data.signature;
          };
          const tcVod = new TcVod({
            getSignature: getSignature, //上传签名的函数
          });
          const uploader = tcVod.upload({
            mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
          });
          uploader.done().then((res) => {
            insertFn(res.video.url); // 进行视频处理：转码和截图
          });
        }
      });
  },
};
const columns = [
  {
    title: "进修标题",
    dataIndex: "title",
    width: "23%",
  },
  {
    title: "标记",
    width: "15%",
    scopedSlots: { customRender: "biaoji" },
    align: "center",
  },
  {
    title: "医院",
    dataIndex: "hospitalName",
    width: "14%",
    align: "center",
  },
  {
    title: "进修科室",
    width: "10%",
    scopedSlots: { customRender: "keshi" },
    align: "center",
  },
  {
    title: "报名时间",
    width: "17%",
    scopedSlots: { customRender: "time" },
    align: "center",
  },
  {
    title: "创建人",
    dataIndex: "createdByName",
    width: "7%",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    width: "14%",
    align: "center",
  },
  {
    title: "到期状态",
    dataIndex: "isDeadLine",
    key: "isDeadLine",
    align: "center",
    scopedSlots: { customRender: "isDeadLine1" },
    width: "6%",
  },
  {
    title: "是否发布",
    width: "7%",
    scopedSlots: { customRender: "fabu" },
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    fixed: "right",
    scopedSlots: { customRender: "operation" },
    align: "center",
  },
];

export default {
  components: { Editor, Toolbar },
  data() {
    return {
      promotionId:'',
      uniAppColumns:[
        {
          title: '小程序',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      uniAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      deadlinState: "全部",
      recommendId: "",
      promotionsVisible: false,
      communityColumns: [
        {
          title: "模块",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "状态",
          dataIndex: "is_open",
          key: "is_open",
          scopedSlots: { customRender: "is_open" },
        },
      ],
      communityData: [
        {
          id: 1,
          title: "进修招聘首页",
          is_open: false,
        },
      ],
      City: City,
      columns,
      year: moment(current_date()).format("YYYY"),
      yearShowOne: false,
      name: "",
      NX: [],
      XL: [],
      ZS: [],
      YJKS:[],
      addHospitalVisible: false,
      editHospitalVisible: false,
      addKeshiVisible: false,
      editKeshiVisible: false,
      labelColLive: { span: 5 },
      wrapperCol: { span: 17 },
      tableList: [],
      title: "",
      total: 0,
      curPage: 1,
      pageSize: 10,
      hospitaloPtions: [],
      keshiList: [],
      tags: [],
      tagsHospital: [],
      inputVisible: false,
      inputValue: "",
      setupVisible: false,
      hospitalNameList: [],
      hospitalNameList_pageNo: 1,
      addKeshiForm: {
        introduce: "",
        hospitalDepartmentId: "",
      }, //科室表单
      addHospitalForm: {
        hospitalDepartmentId: [],
      }, //医院表单
      setupForm: {}, //设置表单
      editKeshiForm: {}, //科室表单
      editHospitalForm: {}, //医院表单
      menuList: [], //设置左侧菜单
      selectId: "",
      parent_id: "",
      hospitalId: "",
      flag: false,
      editor: null,
      toolbarConfig: {},
      mode: "default", // or 'simple'
      editorConfig,
      selectedKeys: [],
      state: "1",
      addHospitalRules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        cycle: [{ required: true, message: "请选择", trigger: "blur" }],
        education: [{ required: true, message: "请选择", trigger: "blur" }],
        stay: [{ required: true, message: "请选择", trigger: "blur" }],
        province: [{ required: true, message: "请选择", trigger: "blur" }],
        provinceCity: [{ required: true, message: "请选择", trigger: "blur" }],
        hospitalId: [{ required: true, message: "请选择", trigger: "blur" }],
        hospitalDepartmentId: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        firstLevelHospitalDepartment:[{ required: true, message: "请选择", trigger: "blur" }],
        startTime: [{ required: true, message: "请选择", trigger: "change" }],
        endTime: [{ required: true, message: "请选择", trigger: "change" }],
        introduce: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      otherForm: {},
      otherTag: [],
      hospitalPage: {
        name: undefined,
        current: 1,
      },
    };
  },
  created() {
    this.load();
    this.getPCH();
    this.getSelectionNX();
    this.getSelectionXL();
    this.getSelectionZS();
    this.getSelectionYJKS();
  },

  methods: {
    // async minaRecommend(){
    //   const data = {
    //     contentId:this.promotionId,
    //     contentType:'EDUCATION',
    //     // subContentType:this.subContentType,
    //   }
    //   const response = await postminaRecommend(data)
    //   if(response.code === 0){
    //     this.$message.success("操作成功！")
    //   }else {
    //     this.$message.warning(response.message)
    //   }
    // },
    async minaRecommend(record){
      if(record.is_open === true){
        const data = {
          contentId:this.promotionId,
          contentType:'EDUCATION',
          // subContentType:this.subContentType,
        }
        const response = await postminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else{
        const data = {
          contentId:this.promotionId,
          contentType:'EDUCATION',
          // subContentType:this.subContentType,
        }
        const response = await delminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 分页查询
    pageChange(curPage, pageSize) {
      this.curPage = curPage;
      this.pageSize = pageSize;
      this.load();
    },
    delCancel() {},
    async load() {
      let isDeadLine =
        this.deadlinState == "全部"
          ? ""
          : this.deadlinState == "未到期"
          ? 0
          : 1;
      const res = await getEducation(
        "education",
        this.name,
        this.curPage,
        this.pageSize,
        this.year,
        this.state,
        isDeadLine
      );
      if (res.code === 0) {
        this.tableList = res.data;
        this.total = res.count;
        // console.log(this.tableList);
      }
    },
    search() {
      this.curPage = 1;
      this.load();
    },
    clear() {
      this.name = "";
      this.year = moment(current_date()).format("YYYY");
      this.state = "1";
      this.deadlinState ="全部";
      this.curPage = 1;
      this.load();
    },
    callback(e) {
      let activeKey = e.target.value;
      switch (activeKey) {
        case "1":
          this.state = 1;
          this.curPage = 1;
          this.load();
          break;
        case "0":
          this.state = 0;
          this.curPage = 1;
          this.load();
          break;
        default:
          break;
      }
    },
    handleChange(value) {
      switch (value) {
        case "全部":
          (this.deadlinState = "全部"), (this.curPage = 1);
          this.load();
          break;
        case "未到期":
          (this.deadlinState = "未到期"), (this.curPage = 1);
          this.load();
          break;
        case "已到期":
          (this.deadlinState = "已到期"), (this.curPage = 1);
          this.load();
          break;
      }
    },
    //弹出日历和关闭日历的回调
    openChangeOne(status) {
      //status是打开或关闭的状态
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.year = value;
      this.yearShowOne = false;
      if (this.year) {
        this.year = moment(this.year).format("YYYY");
      }
    },
    // 置顶开关
    async isTopSwitch(value, record) {
      let data = {
        id: record.id,
        isTop: value,
      };
      const res = await editEducation(data);
      if (res.code === 0) {
        this.$message.success("修改成功！");
        await this.load();
      } else {
        this.$message.warn(res.message);
      }
    },
    // 新开关
    async isNewSwitch(value, record) {
      let data = {
        id: record.id,
        isNew: value,
      };
      const res = await editEducation(data);
      if (res.code === 0) {
        this.$message.success("修改成功！");
        await this.load();
      } else {
        this.$message.warn(res.message);
      }
    },
    // 急开关
    async isImpatientSwitch(value, record) {
      let data = {
        id: record.id,
        isImpatient: value,
      };
      const res = await editEducation(data);
      if (res.code === 0) {
        this.$message.success("修改成功！");
        await this.load();
      } else {
        this.$message.warn(res.message);
      }
    },
    // 发布开关
    async isPublishSwitch(value, record) {
      let data = {
        id: record.id,
        isPublish: value,
      };
      const res = await editEducation(data);
      if (res.code === 0) {
        this.$message.success("修改成功！");
        await this.load();
      } else {
        this.$message.warn(res.message);
      }
    },
    // 删除
    async del(id) {
      const res = await delEducation(id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.load();
      } else {
        this.$message.warn(res.message);
      }
    },

    // 打开编辑
    edit(record) {
      this.hospitalPage.current = 1;
      this.load();
      if (record.createdByType === "department") {
        // console.log("编辑", record);
        this.tags = record.tags;
        this.provinceChange(record.provinceCity);
        this.hospitalSeleChange(record.hospitalId);
        this.editKeshiForm = record;
        this.editKeshiVisible = true;
        this.editKeshiForm.firstLevelHospitalDepartment=this.editKeshiForm.firstLevelHospitalDepartment.split('、')
      } else {
        // console.log("编辑", record);
        this.tags = record.tags;
        this.provinceChange(record.provinceCity);
        this.hospitalSeleChange(record.hospitalId);
        this.editHospitalForm = record;
        this.editHospitalVisible = true;
         this.editHospitalForm.firstLevelHospitalDepartment=this.editHospitalForm.firstLevelHospitalDepartment.split('、')
      }
    },

    // ============================================
    // 表单
    // 周期
    async getSelectionNX() {
      const res = await getSelect("NX");
      if (res.code == 0) {
        this.NX = res.data.value;
      }
    },
    // 学历
    async getSelectionXL() {
      const res = await getSelect("XL");
      if (res.code == 0) {
        this.XL = res.data.value;
      }
    },
    // 住宿
    async getSelectionZS() {
      const res = await getSelect("ZS");
      if (res.code == 0) {
        this.ZS = res.data.value;
      }
    },
    // 一级科室
    async getSelectionYJKS() {
      const res = await getSelect("YJKS");
      if (res.code == 0) {
        this.YJKS = res.data.value;
      }
    },
    // 按医院创建
    createdByHospital() {
      this.hospitalPage.current = 1;
      this.addHospitalVisible = true;
      this.addHospitalForm = {};
    },
    // 按科室创建
    createdByKeshi() {
      this.hospitalPage.current = 1;
      this.addKeshiVisible = true;
      this.addKeshiForm = {};
    },

    // 标签
    handleClose(removedTag) {
      const tags = this.tags.filter((tag) => tag !== removedTag);
      this.tags = tags;
    },

    showInput() {
      if (this.tags.length < 2) {
        this.inputVisible = true;
        this.$nextTick(function () {
          this.$refs.input.focus();
        });
      } else {
        this.$message.warn("标签最多为2个！");
      }
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      Object.assign(this, {
        tags,
        inputVisible: false,
        inputValue: "",
      });
    },

    // 省份 下拉省份选择
    getPCH() {
      let province = [];
      this.City.forEach((item, index) => {
        let city = [];
        province.push({
          value: item.name,
          label: item.name,
          children: city,
        });
        item.cityList.forEach((list) => {
          city.push({
            value: list.name,
            label: list.name,
            loading: false,
          });
        });
        province[index].children = city;
      });
      this.hospitaloPtions = province;
    },
    // 医院列表  根据选择的省份显示下拉
    provinceChange(value) {
      this.hospitalPage.current = 1;
      this.hospitalNameList = [];
      this.keshiList = [];
      this.$set(this.addHospitalForm, "hospitalId", undefined);
      this.$set(this.addKeshiForm, "hospitalId", undefined);
      this.$set(this.editKeshiForm, "hospitalId", undefined);
      this.$set(this.editHospitalForm, "hospitalId", undefined);
      this.$set(this.addHospitalForm, "hospitalDepartmentId", undefined);
      this.$set(this.addKeshiForm, "hospitalDepartmentId", undefined);
      this.$set(this.editKeshiForm, "hospitalDepartmentId", undefined);
      this.$set(this.editHospitalForm, "hospitalDepartmentId", undefined);
      let data = {
        province: value[0],
        city: value[1],
        pagenum: this.hospitalNameList_pageNo,
      };
      this.getHosNameList(data);
    },
    // async getHosNameList(data) {
    //   const response = await getHospital(data);
    //   if (response.code === 0) {
    //     this.hospitalNameList = response.data.rows;
    //   }
    // },
    //医院数据
    async getHosNameList(data) {
      const response = await getHospital(data);
      if (response.code === 0) {
        let list = response.data.rows;
        for (let i = 0; i < list.length; i++) {
          this.hospitalNameList.push({
            id: list[i].id,
            name: list[i].name,
          });
        }
      }
    },
    // 获取科室列表  根据医院id
    hospitalSeleChange(id) {
      this.keshiList = [];
      this.$set(this.addHospitalForm, "hospitalDepartmentId", undefined);
      this.$set(this.addKeshiForm, "hospitalDepartmentId", undefined);
      this.$set(this.editKeshiForm, "hospitalDepartmentId", undefined);
      this.$set(this.editHospitalForm, "hospitalDepartmentId", undefined);
      getHospitalDepartments(id).then((res) => {
        if (res.code == 0) {
          this.keshiList = res.data;
          // console.log("科室", this.keshiList);
        }
      });
    },
    onStartChange(date, dateString) {
      this.addKeshiForm.startTime = dateString;
    },
    onEndChange(date, dateString) {
      this.addKeshiForm.endTime = dateString;
    },
    onStartHospitalChange(date, dateString) {
      this.addHospitalForm.startTime = dateString;
    },
    onEndHospitalChange(date, dateString) {
      this.addHospitalForm.endTime = dateString;
    },
    onStartSetupChange(date, dateString) {
      this.setupForm.startTime = dateString;
    },
    onEndSetupChange(date, dateString) {
      this.setupForm.endTime = dateString;
    },
    ondeadlineTimeSetupChange(date, dateString) {
      this.setupForm.deadlineTime = dateString;
    },
    ondeadlineTimeHospitalChange(date, dateString) {
      // this.$set(this.addHospitalForm, "deadlineTime", dateString);
      this.addHospitalForm.deadlineTime = dateString;
    },
    ondeadlineTimeChange(date, dateString) {
      this.addKeshiForm.deadlineTime = dateString;
      // this.$set(this.addKeshiForm, "deadlineTime", dateString);
    },

    //按科室创建 提交确定
    async addKeshiOK() {
      if (this.addKeshiForm.introduce == "<p><br></p>") {
        delete this.addKeshiForm.introduce;
      }
      this.$refs.ruleKeshiForm_add.validate(async (valid) => {
        if (valid) {
          let data = {
            createdByType: "department",
            type: "education",
            title: this.addKeshiForm.title,
            cycle: this.addKeshiForm.cycle,
            education: this.addKeshiForm.education,
            stay: this.addKeshiForm.stay,
            tags: this.tags,
            provinceCity: this.addKeshiForm.province,
            hospitalId: this.addKeshiForm.hospitalId,
            hospitalDepartmentId: [this.addKeshiForm.hospitalDepartmentId],
            startTime: this.addKeshiForm.startTime,
            endTime: this.addKeshiForm.endTime,
            introduce: this.addKeshiForm.introduce,
            deadlineTime: this.addKeshiForm.deadlineTime,
            isPublish: 1,
            firstLevelHospitalDepartment:this.addKeshiForm.firstLevelHospitalDepartment.join("、")
          };
          // console.log("表单", data);
          const res = await addEducation(data);
          if (res.code == 0) {
            this.$message.success("新建成功");
            this.addKeshiVisible = false;
            this.load();
            this.addKeshiForm = {};
            this.tags = [];
            this.hospitalNameList = []; //清空医院表单
            this.keshiList = []; //清空科室表单
          } else {
            this.$message.warn(res.message);
          }
        }
      });
    },

    // 按医院创建 提交确定
    async addHospitalOK() {
      if (this.addHospitalForm.introduce == "<p><br></p>") {
        delete this.addHospitalForm.introduce;
      }
      this.$refs.ruleHospitalForm_add.validate(async (valid) => {
        if (valid) {
          let data = {
            createdByType: "hospital",
            type: "education",
            title: this.addHospitalForm.title,
            cycle: this.addHospitalForm.cycle,
            education: this.addHospitalForm.education,
            stay: this.addHospitalForm.stay,
            tags: this.tags,
            provinceCity: this.addHospitalForm.province,
            hospitalId: this.addHospitalForm.hospitalId,
            hospitalDepartmentId: this.addHospitalForm.hospitalDepartmentId,
            startTime: this.addHospitalForm.startTime,
            endTime: this.addHospitalForm.endTime,
            introduce: this.addHospitalForm.introduce,
            deadlineTime: this.addHospitalForm.deadlineTime,
            isPublish: 1,
            firstLevelHospitalDepartment: this.addHospitalForm.firstLevelHospitalDepartment.join("、")
          };
          // console.log("表单医院", data);
          const res = await addEducation(data);
          if (res.code == 0) {
            this.$message.success("新建成功");
            this.addHospitalVisible = false;
            this.load();
            this.addHospitalForm = {};
            this.tags = [];
            this.hospitalNameList = []; //清空医院表单
            this.keshiList = []; //清空科室表单
          } else {
            this.$message.warn(res.message);
          }
        }
      });
    },
    // 按科室编辑 确定提交
    async editKeshiOK() {
      if (this.editKeshiForm.introduce == "<p><br></p>") {
        delete this.editKeshiForm.introduce;
      }
      this.$refs.ruleKeshiForm_edit.validate(async (valid) => {
        if (valid) {
          let data = {
            id: this.editKeshiForm.id,
            createdByType: "department",
            type: "education",
            title: this.editKeshiForm.title,
            cycle: this.editKeshiForm.cycle,
            education: this.editKeshiForm.education,
            stay: this.editKeshiForm.stay,
            tags: this.tags,
            provinceCity: this.editKeshiForm.provinceCity,
            hospitalId: this.editKeshiForm.hospitalId,
            hospitalDepartmentId: [this.editKeshiForm.hospitalDepartmentId],
            startTime: this.editKeshiForm.startTime,
            endTime: this.editKeshiForm.endTime,
            introduce: this.editKeshiForm.introduce,
            isPublish: this.editKeshiForm.isPublish,
            deadlineTime: this.editKeshiForm.deadlineTime,
            firstLevelHospitalDepartment:this.editKeshiForm.firstLevelHospitalDepartment.join("、")
          };
          // console.log("编辑后", data);
          const res = await editEducation(data);
          if (res.code == 0) {
            this.$message.success("编辑成功！");
            this.editKeshiForm = {};
            this.load();
            this.tags = [];
            this.editKeshiVisible = false;
            this.hospitalNameList = []; //清空医院表单
            this.keshiList = []; //清空科室表单
          }
        }
      });
    },
    // 按医院编辑 确定提交
    async editHospitalOK() {
      if (this.editHospitalForm.introduce == "<p><br></p>") {
        delete this.editHospitalForm.introduce;
      }
      this.$refs.ruleHospitalForm_edit.validate(async (valid) => {
        if (valid) {
          let data = {
            id: this.editHospitalForm.id,
            createdByType: "hospital",
            type: "education",
            title: this.editHospitalForm.title,
            cycle: this.editHospitalForm.cycle,
            education: this.editHospitalForm.education,
            stay: this.editHospitalForm.stay,
            tags: this.tags,
            provinceCity: this.editHospitalForm.provinceCity,
            hospitalId: this.editHospitalForm.hospitalId,
            hospitalDepartmentId: this.editHospitalForm.hospitalDepartmentId,
            startTime: this.editHospitalForm.startTime,
            endTime: this.editHospitalForm.endTime,
            introduce: this.editHospitalForm.introduce,
            isPublish: this.editHospitalForm.isPublish,
            deadlineTime: this.editHospitalForm.deadlineTime,
            firstLevelHospitalDepartment:this.editHospitalForm.firstLevelHospitalDepartment.join("、")
          };
          // console.log("编辑后医院,", data);
          const res = await editEducation(data);
          if (res.code == 0) {
            this.$message.success("编辑成功！");
            this.editHospitalForm = {};
            this.load();
            this.tags = [];
            this.editHospitalVisible = false;
            this.hospitalNameList = []; //清空医院表单
            this.keshiList = []; //清空科室表单
          }
        }
      });
    },

    async addHospitalCancel() {
      this.addHospitalVisible = false;
      this.load();
      this.addHospitalForm = {};
      this.hospitalNameList = []; //清空医院表单
      this.keshiList = []; //清空科室表单
      this.tags = [];
      this.$refs.ruleHospitalForm_add.resetFields();
    },
    async addKeshiCancel() {
      this.addKeshiVisible = false;
      this.load();
      this.addKeshiForm = {};
      this.tags = [];
      this.$refs.ruleKeshiForm_add.resetFields();
      this.hospitalNameList = []; //清空医院表单
      this.keshiList = []; //清空科室表单
    },
    async editKeshiCancel() {
      this.editKeshiVisible = false;
      this.load();
      this.editKeshiForm = {};
      this.tags = [];
      this.$refs.ruleKeshiForm_edit.resetFields();
      this.hospitalNameList = []; //清空医院表单
      this.keshiList = []; //清空科室表单
    },
    async editHospitalCancel() {
      this.editHospitalVisible = false;
      this.load();
      this.editHospitalForm = {};
      this.tags = [];
      this.$refs.ruleHospitalForm_edit.resetFields();
      this.hospitalNameList = []; //清空医院表单
      this.keshiList = []; //清空科室表单
    },
    // 打开设置
    async openSetup(record) {
      this.setupVisible = true;
      this.menuList = record.educationHospitalDepartment;
      // console.log("33", this.menuList);
      this.selectedKeys = [this.menuList[0].id];
      this.parent_id = record.id; //父级id
      this.hospitalId = record.hospitalId; //医院id
      // 默认获取第一个表单
      const res = await getChildEducation(this.parent_id, this.menuList[0].id);
      if (res.code == 0) {
        if (res.data !== undefined) {
          this.flag = false; //存在为修改科室信息
          this.tags = [];
          this.setupForm = {};
          this.setupForm = res.data; //给表单复值 用于回显
          this.setupForm.hospitalDepartmentId = this.menuList[0].id;
          this.tags = res.data.tags;
          this.$refs["setupForm"].clearValidate(); // 只清除清除验证
          this.otherForm = { ...this.setupForm }; //原始表单 用于比较
          this.otherTag = [...this.tags];
          // console.log(this.otherTag);
        } else {
          this.flag = true; //不存在为添加科室信息
          this.setupForm = {};
          this.setupForm.hospitalDepartmentId = this.menuList[0].id;
          (this.setupForm.isNew = 0),
            (this.setupForm.isTop = 0),
            (this.setupForm.isImpatient = 0),
            (this.tags = []);
          this.$refs["setupForm"].clearValidate(); // 只清除清除验证
          this.otherForm = { ...this.setupForm }; //原始表单 用于比较
          this.otherTag = [...this.tags];
        }
      }
    },
    async setupChange(id) {
      // 根据id发送请求
      const res = await getChildEducation(this.parent_id, id);
      if (res.code == 0) {
        if (res.data !== undefined) {
          this.flag = false; //存在为修改科室信息
          this.tags = [];
          this.setupForm = {};
          this.setupForm = res.data; //给表单复值
          this.setupForm.hospitalDepartmentId = id;
          this.tags = res.data.tags;
          this.$refs["setupForm"].clearValidate(); // 只清除清除验证
          this.otherForm = {};
          this.otherForm = { ...this.setupForm };
          this.otherTag = [...this.tags];
          // console.log(this.otherTag);
        } else {
          this.flag = true; //不存在为添加科室信息
          this.setupForm = {};
          this.setupForm.hospitalDepartmentId = id;
          (this.setupForm.isNew = 0),
            (this.setupForm.isTop = 0),
            (this.setupForm.isImpatient = 0),
            (this.tags = []);
          this.$refs["setupForm"].clearValidate(); // 只清除清除验证
          this.otherForm = { ...this.setupForm }; //原始表单 用于比较
          this.otherTag = [...this.tags];
        }
      }

      // if (item !== undefined) {
      //   const res = await getChildEducation(this.parent_id, id);
      //   if (res.code == 0) {
      //     this.flag = false; //存在为修改科室信息
      //     this.setupForm = {};
      //     this.tags = [];
      //     this.setupForm = res.data;
      //     this.setupForm.hospitalDepartmentId = res.data.id;
      //     this.tags = res.data.tags;
      //     console.log(this.tags);
      //     this.$refs["setupForm"].clearValidate(); // 只清除清除验证
      //   }
      // } else {
      //   this.flag = true; //不存在为添加科室信息
      //   this.setupForm = {};
      //   this.setupForm.hospitalDepartmentId = item.id;
      //   this.tags = [];
      //   this.$refs["setupForm"].clearValidate(); // 只清除清除验证
      // }
    },
    // 设置确定
    async setupOk() {
      // console.log("比较一下", this.tags, this.otherTag);
      // console.log("flag", this.flag);
      if (!this.flag) {
        this.$refs.setupForm.validate(async (valid) => {
          if (valid) {
            // 修改科室信息
            let data = {
              createdByType: "department",
              type: "education",
              id: this.setupForm.id,
              parentId: this.parent_id, //医院父级id
              hospitalDepartmentId: [this.setupForm.hospitalDepartmentId], //编辑的科室id
              title: this.setupForm.title,
              cycle: this.setupForm.cycle,
              education: this.setupForm.education,
              stay: this.setupForm.stay,
              tags: this.tags,
              startTime: this.setupForm.startTime,
              endTime: this.setupForm.endTime,
              introduce: this.setupForm.introduce,
              notes: this.setupForm.notes,
              isTop: this.setupForm.isTop,
              isNew: this.setupForm.isNew,
              isImpatient: this.setupForm.isImpatient,
              deadlineTime: this.setupForm.deadlineTime,
            };
            if (
              JSON.stringify(this.otherForm) !==
                JSON.stringify(this.setupForm) ||
              JSON.stringify(this.tags) !== JSON.stringify(this.otherTag)
            ) {
              const res = await editEducation(data);
              if (res.code == 0) {
                this.$message.success("设置成功！");
                this.otherForm = { ...this.setupForm };
                this.otherTag = [...this.tags];
                // this.flag = true;
                // this.setupVisible = false;
                // this.setupForm = {};
                // this.tags = [];
                this.load();
              } else {
                this.$message.warn(res.message);
              }
            } else {
              this.$message.warn("请修改后保存！");
            }
          }
        });
      } else {
        // 添加科室信息
        if (this.setupForm.introduce == "<p><br></p>") {
          delete this.setupForm.introduce;
        }
        this.$refs.setupForm.validate(async (valid) => {
          if (valid) {
            let data = {
              createdByType: "department",
              type: "education",
              hospitalId: this.hospitalId,
              parentId: this.parent_id, //医院父级id
              hospitalDepartmentId: [this.setupForm.hospitalDepartmentId], //编辑的科室id
              title: this.setupForm.title,
              cycle: this.setupForm.cycle,
              education: this.setupForm.education,
              stay: this.setupForm.stay,
              tags: this.tags,
              startTime: this.setupForm.startTime,
              endTime: this.setupForm.endTime,
              introduce: this.setupForm.introduce,
              notes: this.setupForm.notes,
              isTop: this.setupForm.isTop,
              isNew: this.setupForm.isNew,
              isImpatient: this.setupForm.isImpatient,
              deadlineTime: this.setupForm.deadlineTime,
            };
            const res = await addEducation(data);
            if (res.code == 0) {
              this.flag = false;
              this.$message.success("设置成功！");
              const res = await getChildEducation(
                this.parent_id,
                this.setupForm.hospitalDepartmentId
              );
              if (res.code == 0) {
                this.flag = false; //存在为修改科室信息
                this.tags = [];
                this.setupForm = {};
                this.setupForm = res.data; //给表单复值
                this.tags = res.data.tags;
                this.$refs["setupForm"].clearValidate(); // 只清除清除验证
                this.otherForm = {};
                this.otherForm = { ...this.setupForm };
                this.otherTag = [...this.tags];
              }
              this.load();
            } else {
              this.$message.warn(res.message);
            }
          }
        });
      }
    },
    setupCancel() {
      this.setupVisible = false;
      this.selectedKeys = [];
      this.setupForm = {};
      this.tags = [];
      this.$refs.setupForm.resetFields();
    },
    promotionsClose() {
      this.promotionsVisible = false;
    },
    // 推荐
    async openRecommend(record) {
      this.promotionsVisible = true;
      this.recommendId = record.id;
      this.promotionId = record.otherId
      const res = await getRecommend(record.id);
      if (res.data === 1) {
        this.communityData[0].is_open = true;
      } else {
        this.communityData[0].is_open = false;
      }
      this.minaRecord()
    },
    //  小程序推广回显
    async minaRecord(){
      // 小程序推广回显  
      const data = {
        contentId:this.promotionId,
        contentType:'EDUCATION',
        // subContentType:this.subContentType,
      }
      const response = await getminaRecommend(data)
      if (response.code === 0) {
        if(response.data[0] && response.data[0].code === "YUN_ICU"){
           this.uniAppData[0].is_open = true
        }else{
          this.uniAppData[0].is_open = false
        }
      } else {
        this.$message.warning(response.message)
      }
   },
    async isRecommendSwitch(boolean) {
      if (boolean) {
        let data = {
          contentId: this.recommendId,
        };
        const res = await postRecommend(data);
        if (res.code == 0) {
          this.$message.success("推广成功！");
          // this.promotionsVisible = false;
        }
      } else {
        const res = await delRecommend(this.recommendId);
        if (res.code == 0) {
          this.$message.success("关闭成功！");
          this.promotionsVisible = false;
        }
      }
    },
    // 新建医院滚动条监听
    popupScroll_questionCode(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.hospitalPage.current++;
        let values = this.addHospitalForm.province;
        const data = {
          province: values[0],
          city: values[1],
          name: this.hospitalPage.name,
          pagenum: this.hospitalPage.current,
          pagesize: 10,
        };
        this.getHosNameList(data);
      }
    },
    // 新建科室滚动条监听
    PopupScroll_questionCodeTwo(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.hospitalPage.current++;
        let values = this.addKeshiForm.province;
        const data = {
          province: values[0],
          city: values[1],
          name: this.hospitalPage.name,
          pagenum: this.hospitalPage.current,
          pagesize: 10,
        };
        this.getHosNameList(data);
      }
    },
    // 编辑按科室滚动条监听
    editPopupScroll_questionCode(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.hospitalPage.current++;
        let values = this.editKeshiForm.provinceCity;
        const data = {
          province: values[0],
          city: values[1],
          name: this.hospitalPage.name,
          pagenum: this.hospitalPage.current,
          pagesize: 10,
        };
        this.getHosNameList(data);
      }
    },
    // 编辑按医院滚动条监听
    editPopupScroll_questionCodeTwo(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.hospitalPage.current++;
        let values = this.editHospitalForm.provinceCity;
        const data = {
          province: values[0],
          city: values[1],
          name: this.hospitalPage.name,
          pagenum: this.hospitalPage.current,
          pagesize: 10,
        };
        this.getHosNameList(data);
      }
    },
    // 按医院创建医院框输入
    select_hospital(value) {
      this.hospitalNameList = [];
      this.hospitalPage.name = value;
      let values = this.addHospitalForm.province;
      const data = {
        province: values[0],
        city: values[1],
        name: value,
        pagenum: 1,
        pagesize: 10,
      };
      this.getHosNameList(data);
    },
    // 按科室创建医院框输入
    select_2hospital(value) {
      this.hospitalNameList = [];
      this.hospitalPage.name = value;
      let values = this.addKeshiForm.province;
      const data = {
        province: values[0],
        city: values[1],
        name: value,
        pagenum: 1,
        pagesize: 10,
      };
      this.getHosNameList(data);
    },
    // 按科室修改医院框输入
    select_3hospital(value) {
      this.hospitalNameList = [];
      this.hospitalPage.name = value;
      let values = this.editKeshiForm.provinceCity;
      const data = {
        province: values[0],
        city: values[1],
        name: value,
        pagenum: 1,
        pagesize: 10,
      };
      this.getHosNameList(data);
    },
    // 按医院修改医院框输入
    select_4hospital(value) {
      this.hospitalNameList = [];
      this.hospitalPage.name = value;
      let values = this.editHospitalForm.provinceCity;
      const data = {
        province: values[0],
        city: values[1],
        name: value,
        pagenum: 1,
        pagesize: 10,
      };
      this.getHosNameList(data);
    },
    isTopSetup(isTop) {
      this.$set(this.setupForm, "isTop", isTop);
      this.$forceUpdate();
      // console.log(isTop);
    },

    isNewSetup(isNew) {
      this.$set(this.setupForm, "isNew", isNew);
      this.$forceUpdate();
      // console.log(isNew);
    },
    isImpatientSetup(isImpatient) {
      this.$set(this.setupForm, "isImpatient", isImpatient);
      this.$forceUpdate();
      // console.log(this.setupForm.isImpatient);
    },
  },
};
</script>
<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}
.body {
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-flow: column;
}
.modalDiv {
  width: 100%;
  display: flex;
  margin-top: -24px;
  margin-left: -24px;
  margin-left: -24px;
  margin-bottom: -24px;

  .menu {
    width: 150px;
    border: none;
  }
  .content-list {
    width: 500px;
    margin-top: 10px;
  }
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
// ::v-deep .w-e-text-container {
//   height: 500px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
// }
</style>
